'use client';
import Link from 'next/link';
import { useState } from 'react';

import { Icons } from 'components/elements/icons/icons';

export function Header() {
  // ドロワー
  const [isOpen, setOpen] = useState<boolean>(false);
  const handleMenuOpen = () => {
    setOpen(!isOpen);
    document.querySelector('body')!.style.overflow = 'hidden';
  };
  const handleMenuClose = () => {
    setOpen(false);
    document.querySelector('body')!.style.overflow = 'visible';
  };

  const navMenu = [
    {
      id: 'nav1',
      menu: 'Home',
      path: '/',
    },
    {
      id: 'nav2',
      menu: 'Profile',
      path: '/profile',
    },
    {
      id: 'nav3',
      menu: 'Used Surf Shop',
      path: 'https://amoten1173.base.shop/?fbclid=PAAabspn6Hcsan16T4AgxoCN9TKbVoooI1irybs1slRIefVgNaDJlN9Vc8vD0_aem_AQjq2xzX8lb7Jfxks5gj_sJhdfxRG2UxwB-vH9icRNc-a56a7L9K2iEkW1DaVMZuvW8',
      newTab: true,
      childNav: [
        {
          id: 'childNav1',
          menu: 'mercari',
          path: 'https://mercari-shops.com/shops/i9SsnmzUCgm9ktsvmKA5nm?_gl=1*bgtv6l*_gcl_aw*R0NMLjE3MTYzNDEwNTguQ2owS0NRandqTEd5QmhDWUFSSXNBUHFUejEtTGpFaUZaWTRWSkFXeTZPVkhNTG9CbUdkSjdLUEFZckFjQ29jTUZlUTMyRVhZOEoxZ2Y2MGFBaWFXRUFMd193Y0I.*_gcl_au*MTk2NTA3MzYzNC4xNzE2MjEwMjQ1*_ga*MTg0MDI5NzY1OS4xNzE2MjEwMjQ1*_ga_4NLR7T2LEN*MTcxNjM0MTA1OS4yLjEuMTcxNjM0MTA5MS4yOC4wLjA.&tab=products',
          newTab: true,
        },
        {
          id: 'childNav2',
          menu: 'base',
          path: 'https://amoten1173.base.shop/?fbclid=PAAabspn6Hcsan16T4AgxoCN9TKbVoooI1irybs1slRIefVgNaDJlN9Vc8vD0_aem_AQjq2xzX8lb7Jfxks5gj_sJhdfxRG2UxwB-vH9icRNc-a56a7L9K2iEkW1DaVMZuvW8',
          newTab: true,
        },
      ],
    },
    {
      id: 'nav4',
      menu: 'Contact',
      path: '/contact',
    },
  ];

  return (
    <header className='fixed w-full grid grid-cols-base [&_*]:col-base xs:grid-cols-baseSp xs:py-4 z-10'>
      <div className='flex items-center justify-between'>
        <div className='relative items-center justify-between hidden xs:flex'>
          <button
            className={
              isOpen ? 'hidden' : 'z-50 space-y-1 invisible xs:visible'
            }
            onClick={handleMenuOpen}
            aria-label='メニュー項目を開く'
            aria-haspopup='true'
          >
            <span
              className={
                isOpen
                  ? 'hidden'
                  : 'block w-[18px] h-0.5 bg-[#121d38] duration-300'
              }
            />
            <span
              className={
                isOpen
                  ? 'hidden'
                  : 'block w-[18px] h-0.5 bg-[#121d38] duration-300'
              }
            />
            <span
              className={
                isOpen
                  ? 'hidden'
                  : 'block w-[18px] h-0.5 bg-[#121d38] duration-300'
              }
            />
          </button>
        </div>

        <div
          onClick={handleMenuClose}
          className={
            isOpen
              ? 'xs:w-full xs:fixed xs:z-30 xs:translate-x-0 xs:h-screen xs:bg-black xs:bg-opacity-70 xs:top-0 xs:left-0'
              : 'hidden xs:block xs:w-full xs:-translate-x-full'
          }
        ></div>

        <Link href='/'>
          <Icons.logo className='w-auto h-[64px] xs:h-[32px]' />
        </Link>

        <nav
          aria-label='グローバルナビゲーション'
          className={
            isOpen
              ? 'duration-300 w-full max-w-[1320px] px-[30px] mx-auto xs:px-0 xs:max-w-[325px] xs:fixed xs:z-40 xs:translate-x-0 xs:h-screen xs:top-0 xs:left-0 xs:bg-white xs:mx-0 xs:block'
              : 'duration-300 w-full max-w-[1320px] px-[30px] mx-auto xs:px-0 xs:max-w-[325px] xs:-translate-x-full xs:mx-0 xs:hidden'
          }
        >
          <svg
            className={
              isOpen
                ? 'hidden xs:block absolute -right-10 top-[18px]'
                : 'hidden'
            }
            onClick={handleMenuClose}
            aria-label='メニュー項目を開く'
            aria-haspopup='true'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            width='22px'
            height='21px'
          >
            <path
              fillRule='evenodd'
              fill='rgb(255, 255, 255)'
              d='M11.854,10.496 L21.809,20.008 C22.047,20.235 22.047,20.603 21.809,20.830 C21.691,20.944 21.535,21.001 21.379,21.001 C21.223,21.001 21.068,20.944 20.949,20.830 L10.994,11.318 L1.039,20.830 C0.920,20.944 0.764,21.001 0.609,21.001 C0.453,21.001 0.297,20.944 0.179,20.830 C-0.059,20.603 -0.059,20.235 0.179,20.008 L10.134,10.496 L0.179,0.984 C-0.059,0.757 -0.059,0.389 0.179,0.162 C0.416,-0.065 0.801,-0.065 1.039,0.162 L10.994,9.674 L20.949,0.162 C21.187,-0.065 21.572,-0.065 21.809,0.162 C22.047,0.389 22.047,0.757 21.809,0.984 L11.854,10.496 Z'
            />
          </svg>
          <ul className='flex gap-9 text-[13px] leading-[16px] tracking-[.08em] items-center justify-end xs:flex-col xs:gap-0'>
            {navMenu.length &&
              navMenu.map((nav) => {
                return (
                  <li
                    key={nav.id}
                    onClick={handleMenuClose}
                    className={`xs:border-b border-borderColor-gray xs:relative xs:w-full ${nav.childNav?.length && `xs:p-0`}`}
                  >
                    {nav.childNav?.length ? (
                      <>
                        <div className='relative block cursor-pointer group/is-hover xs:pt-4'>
                          <div className='xs:pl-5'>{nav.menu}</div>
                          <ul className='absolute hidden p-4 bg-white shadow-lg group-hover/is-hover:block xs:block xs:relative xs:shadow-none xs:p-0 xs:mt-6 xs:pl-9'>
                            {nav.childNav.map((childNav) => {
                              return (
                                <li key={childNav.id}>
                                  <Link
                                    href={childNav.path}
                                    target={childNav.newTab ? '_blank' : ''}
                                    onClick={handleMenuClose}
                                    className='flex items-center justify-between px-3 pb-3 text-sm xs:py-4 xs:px-0 xs:border-t'
                                  >
                                    {childNav.menu}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </>
                    ) : (
                      <Link
                        href={nav.path}
                        target={nav.newTab ? '_blank' : ''}
                        className='flex items-center justify-between text-sm xs:p-5'
                      >
                        {nav.menu}
                      </Link>
                    )}
                  </li>
                );
              })}
          </ul>
        </nav>
      </div>
    </header>
  );
}
