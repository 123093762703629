import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["BailoutToCSR"] */ "/opt/buildhome/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager", webpackExports: ["PreloadCss"] */ "/opt/buildhome/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Shippori_Mincho_B1\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"shippori\"}");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/assets/css/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/opt/buildhome/repo/src/components/layouts/Header.tsx");
